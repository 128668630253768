// Generated by Framer (ff6f0b6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["SoRBgJsyg", "bSre0WdWq"];

const serializationHash = "framer-nnpN7"

const variantClassNames = {bSre0WdWq: "framer-v-ywzh1y", SoRBgJsyg: "framer-v-167nzdi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"24": "bSre0WdWq", "36": "SoRBgJsyg"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "SoRBgJsyg"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "SoRBgJsyg", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-167nzdi", className, classNames)} data-framer-name={"36"} layoutDependency={layoutDependency} layoutId={"SoRBgJsyg"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({bSre0WdWq: {"data-framer-name": "24"}}, baseVariant, gestureVariant)}><SVG className={"framer-1iut7cd"} data-framer-name={"Ic-arrow-up-36"} layout={"position"} layoutDependency={layoutDependency} layoutId={"gDDbkq5nB"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 36 36\"><path d=\"M 6 6 L 30 6 L 30 30 M 6 30 L 30 6\" fill=\"transparent\" stroke-width=\"2.6\" stroke=\"rgb(20,20,31)\" stroke-miterlimit=\"10\" stroke-dasharray=\"\"></path></svg>"} svgContentId={11606965839} withExternalLayout {...addPropertyOverrides({bSre0WdWq: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 4 4 L 20 4 L 20 20 M 4 20 L 20 4\" fill=\"transparent\" stroke-width=\"1.74\" stroke=\"rgb(20,20,31)\" stroke-miterlimit=\"10\"></path></svg>", svgContentId: 9906287062}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nnpN7.framer-1guc562, .framer-nnpN7 .framer-1guc562 { display: block; }", ".framer-nnpN7.framer-167nzdi { height: 36px; overflow: visible; position: relative; width: 36px; }", ".framer-nnpN7 .framer-1iut7cd { flex: none; height: 36px; left: 0px; position: absolute; top: 0px; width: 36px; }", ".framer-nnpN7.framer-v-ywzh1y.framer-167nzdi { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 24px); width: 24px; }", ".framer-nnpN7.framer-v-ywzh1y .framer-1iut7cd { height: 24px; width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"bSre0WdWq":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerhpvvKldJC: React.ComponentType<Props> = withCSS(Component, css, "framer-nnpN7") as typeof Component;
export default FramerhpvvKldJC;

FramerhpvvKldJC.displayName = "ic-arrow-up-36";

FramerhpvvKldJC.defaultProps = {height: 36, width: 36};

addPropertyControls(FramerhpvvKldJC, {variant: {options: ["SoRBgJsyg", "bSre0WdWq"], optionTitles: ["36", "24"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerhpvvKldJC, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})